import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom"
import { BodySm, TitleXl, TitleSm, TitleMd,BodyRg } from "components/elements";
import { Link } from 'react-router-dom';
import { Container, HeaderHeroWrap, RichTextContainerHead1, BodySmContainer, TitleMdSmContainer, TitleXlContainer, TitleCtaContainer, CtaContainer, BadgeContainer, AnchorContainer } from './elements';
import gsap, { Linear } from 'gsap'
import ViewAll from 'components/ViewAll';



function PurpleAnimHeader({ boldSubText, marginRight, period = true, maxHeight, headerMaxWidth, sectionLabel, maxWidth, heroIntro, backgroundImgTitle, headerSplit, backgroundImage, backgroundImgMbl, subHead, header, introCopy, titleMdPadding, titleXlWidth, cta, anchor, is21Grams }) {
  const [introText, setIntroText] = useState(undefined);
  const [boldText, setBoldText] = useState(undefined);
  const headerSplitTxt = header?.split(" ");


  useEffect(() => {

    if (header) {
      // const splitHead = header?.split(' ');

      if (heroIntro?.content[0].content[0].value === undefined) {
        setIntroText(introCopy)
        setBoldText(boldSubText)
      } else {
        setIntroText(heroIntro?.content[0].content[0].value)
      }



      animateText()
    }
  }, [heroIntro, introCopy, header, boldSubText])



  //ANIMATE EACH WORD OF THE HEADER INDIVIDUALLY //
  const animatedHeaderWords = headerSplitTxt?.map((word, index) => {
    const lastWord = index + 1 === headerSplitTxt.length;

    return (
      <TitleXl color="#fff" fontWeight="900" className="animRichTextTop js-animate-me" key={index}>
        {word}
        {(lastWord && period) ? <span className="magenta">.</span> : <span style={{ fontSize: "1em" }}>&nbsp;</span>}
      </TitleXl>
    );
  });




  const animateText = () => {
    gsap.to(".bgImage", { scale: 1.0, duration: .7, delay: 0.5, ease: "power2.out" })
    gsap.to(".badge", { y: "0%", duration: 0.4, delay: 1.3, ease: Linear.easeNone })
    gsap.to(".tgLogo", { y: "0%", duration: 0.4, delay: 1.3, ease: Linear.easeNone })
    gsap.to(".title", { y: "0%", duration: 0.4, delay: 1.3, ease: Linear.easeNone })
    gsap.to(".js-animate-me", {
      delay: 1.8,
      opacity: 1,
      duration: 1,
      y: 0,
      ease: "back.out(1.5)",
      stagger: {
        amount: .5, //  the total amount of time (in seconds) that gets split up among all the staggers. 
        each: .4, //The amount of time (in seconds) between each sub-tween's start time.
        from: "start" // the position in the array from which the stagger will emanate
      }

    });
    gsap.to(".introText", { opacity: 1, duration: 1.5, delay: 2.7, ease: Linear.easeNone })
    gsap.to(".cta", { opacity: 1, duration: 1.5, delay: 2.7, ease: Linear.easeNone })


  }

  return (
    <Container maxHeight={maxHeight}>
      <HeaderHeroWrap padding={'69px 33px 600px;'} className="bgImage" title={backgroundImgTitle} maxHeight={maxHeight} backgroundImage={backgroundImage} backgroundImgMbl={backgroundImgMbl}>
        {/* GPTW certification */}
        {cta && (
          <BadgeContainer >
            <a href="http://www.greatplacetowork.com/certified-company/7038366" title="Rating and Review" target="_blank" rel="noopener noreferrer">
              <img className="badge" src="/images/gptw-2024.svg" alt="Company Badge" width="100" />
            </a>
          </BadgeContainer>
        )}
        {is21Grams && (
          <BadgeContainer className="logo">
            <img className="tgLogo" src="/images/21GRAMS_White.png" width='200' alt="21 Grams Logo" />
          </BadgeContainer>
        )}
        <BodySmContainer>
          <TitleSm className="title">{sectionLabel}</TitleSm>
        </BodySmContainer>
        <TitleXlContainer titleXlWidth={titleXlWidth}>
          <RichTextContainerHead1 headerMaxWidth={headerMaxWidth} >
            {animatedHeaderWords}
          </RichTextContainerHead1>
        </TitleXlContainer>
        <TitleCtaContainer maxWidth={maxWidth} marginRight={marginRight}>
          <TitleMdSmContainer titleMdPadding={titleMdPadding} >
            <TitleMd className="introText">{subHead}</TitleMd>
            <BodySm className="introText">{introText}</BodySm>
            {boldText &&
              <BodySm fontWeight={'900 !important'} className="introText">{boldText}</BodySm>
            }
          </TitleMdSmContainer>
          {anchor && (
            <AnchorContainer className="cta"  >
              <a href="#news" className='anchorLink'>
                <TitleMd>
                  See us in the news
                </TitleMd>
                <img src="/images/white-down-arrow.png" width="20px" height="15px" className='arrow' alt="Target arrow" />
              </a>
            </AnchorContainer>
          )}
          {cta && (
            <CtaContainer className="cta"  >
              <ViewAll link={'/job-category'} title={"SEE OPEN ROLES"} borderColor="#fff" titleColor="#fff" hoverBackground="#fff" svgColor="#fff" width="245px" height="44px" />
            </CtaContainer>
          )}
        </TitleCtaContainer>
      </HeaderHeroWrap>
    </Container>
  )
}

export default withRouter(PurpleAnimHeader);
